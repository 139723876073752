export const GetUniqueId = {
  data: () => ({}),
  methods: {
    getUniqueIdMethod() {
      let uniqueId = localStorage.getItem("uniqueId");
      if (!uniqueId) {
        uniqueId = this.generateUniqueId();
        localStorage.setItem("uniqueId", uniqueId);
      }
      return localStorage.getItem("uniqueId");
    },
    generateUniqueId() {
      if (window.crypto) {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return array[0].toString(36).substring(2, 11);
      } 
      },
  },
};
