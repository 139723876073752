<template>
  <v-overlay :value="overlay">
    <v-img width="150px" src="@/assets/loader.gif"></v-img>
  </v-overlay>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
    count: Number,
  },
};
</script>
