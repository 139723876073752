import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import SIGNUP from "../views/MainHomePages/SignUpPage.vue";
import AcceptInvitation from "../views/MainHomePages/AcceptInvitation.vue";
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "HomePage",
  //   component: HomePage,
  // },
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/INVITEUSER/:InvitedMemberDetails",
    name: "SIGNUP",
    component: SIGNUP,
  },
  {
    path: "/ACCEPTINVITATION/:InvitedEADetails",
    name: "AcceptInvitation",
    component: AcceptInvitation,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Communities",
        name: "My Community",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/Communities.vue"
          ),
      },

      {
        path: "/Resources",
        name: "Resources",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/Resources.vue"
          ),
      },
      {
        path: "/Insights",
        name: "Insights",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/Insights.vue"
          ),
      },
      {
        path: "/MBCA",
        name: "MBCA",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/MBCAHomePage.vue"
          ),
      },
      {
        path: "/MyProfile",
        name: "My Profile",
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/MyProfile.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
