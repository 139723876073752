import axios from "axios";
export const GetCodeValidation = {
  data: () => ({}),
  methods: {
    async GetCodeValidationMethod(user_email_id, otp) {
      try {
        let data = JSON.stringify({
          user_email_id: user_email_id,
          otp,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${this.GetDynamicURL()}verifyOTP`,
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.GetDynamicURLAPIKey(),
          },
          data: data,
        };

        let result = await axios(config);
        return result;
      } catch (error) {
        console.log("eror", error);
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
    GetDynamicURL() {
      return window.location.hostname === "dev.bankxchange.com"
        ? "https://6cvpo8vzci.execute-api.us-east-1.amazonaws.com/dev/"
        : window.location.hostname === "test.bankxchange.com"
        ? "https://r8x3g0xmm8.execute-api.us-east-1.amazonaws.com/test/"
        : window.location.hostname === "uat.bankxchange.com"
        ? "https://t0x8z52egb.execute-api.us-east-1.amazonaws.com/uat/"
        : "https://1p1nnwv2k2.execute-api.us-east-1.amazonaws.com/live/";
    },
    GetDynamicURLAPIKey() {
      return window.location.hostname === "dev.bankxchange.com"
        ? "bMrmIdV3z3HVEaN6TycW5uWZ7uIMJ8laKzTtUepd"
        : window.location.hostname === "test.bankxchange.com"
        ? "RWeq9qTEI97Gh0JehvHu76Jqk9gX8MlY483LEmN9"
        : window.location.hostname === "uat.bankxchange.com"
        ? "CeAMkxHq6G1K9ytlnpWWX8So8mNPOw2XZjWzZqf4"
        : "YN1S5JEj17983TzmSPUqn2SK52ZtZmgXalrC0lzk";
    },
  },
};
