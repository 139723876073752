import axios from "axios";
export const GetAllBanks = {
  data() {
    return {
      GetAllBanksList: [],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async GetAllBanksMethod() {
      try {
        let data = JSON.stringify({});
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${this.GetDynamicURL()}listBanks`,
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.GetDynamicURLAPIKey(),
          },
          data: data,
        };
        let result = await axios(config);
        if (
          result.status == 200 &&
          result.data &&
          result.data.status &&
          result.data.status == "Success"
        ) {
          this.GetAllBanksList = result.data.data.items;
        }
      } catch (error) {
        console.log("Error In LIST API CLass", error);
      }
    },
    GetDynamicURL() {
      return window.location.hostname === "dev.bankxchange.com" || window.location.hostname === "localhost"
        ? "https://6cvpo8vzci.execute-api.us-east-1.amazonaws.com/dev/"
        : window.location.hostname === "test.bankxchange.com"
        ? "https://r8x3g0xmm8.execute-api.us-east-1.amazonaws.com/test/"
        : window.location.hostname === "uat.bankxchange.com"
        ? "https://t0x8z52egb.execute-api.us-east-1.amazonaws.com/uat/"
        : "https://1p1nnwv2k2.execute-api.us-east-1.amazonaws.com/live/";
    },
    GetDynamicURLAPIKey() {
      return window.location.hostname === "dev.bankxchange.com" || window.location.hostname === "localhost"
        ? "bMrmIdV3z3HVEaN6TycW5uWZ7uIMJ8laKzTtUepd"
        : window.location.hostname === "test.bankxchange.com"
        ? "RWeq9qTEI97Gh0JehvHu76Jqk9gX8MlY483LEmN9"
        : window.location.hostname === "uat.bankxchange.com"
        ? "CeAMkxHq6G1K9ytlnpWWX8So8mNPOw2XZjWzZqf4"
        : "YN1S5JEj17983TzmSPUqn2SK52ZtZmgXalrC0lzk";
    },
  },
};
