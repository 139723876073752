import axios from "axios";
export const GetActivateUser = {
  data() {
    return {
      GetAllBanksList: [],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async GetActivateUserMethod(user_details, contact_details, ContactSettingsValue) {
      try {
        let data = JSON.stringify({
          user_email_id: user_details.user_email_id,
          password: user_details.user_confirm_password,
          user_first_name: contact_details.user_first_name,
          user_last_name: contact_details.user_last_name,
          bank_id: contact_details.bank_details.bank_id,
          bank_name: contact_details.bank_details.bank_name,
          role_id: contact_details.role_id,
          user_contact_number: contact_details.user_contact_number,
          office_contact_number:
            contact_details.office_contact_number && contact_details.office_contact_number != ""
              ? contact_details.office_contact_number
              : undefined,
          linkedin_profile_url:
            contact_details.linkedin_profile_url != "" && contact_details.linkedin_profile_url != null
              ? contact_details.linkedin_profile_url
              : undefined,
          address_line_1: contact_details.address_line_1,
          address_line_2:
            contact_details.address_line_2 != "" && contact_details.address_line_2
              ? contact_details.address_line_2
              : undefined,
          city: contact_details.city,
          zipcode: contact_details.zipcode,
          state: contact_details.state,
          executive_first_name:
            contact_details.executive_first_name != "" && contact_details.executive_first_name
              ? contact_details.executive_first_name
              : undefined,
          executive_last_name:
            contact_details.executive_last_name != "" && contact_details.executive_last_name
              ? contact_details.executive_last_name
              : undefined,

          executive_email_id:
            contact_details.executive_email_address != "" && contact_details.executive_email_address
              ? contact_details.executive_email_address
              : undefined,

          preference_business_email_id: ContactSettingsValue.IsBusinessEmailAddress,
          preference_user_contact_number: ContactSettingsValue.IsBusinessMobilePhone,
          preference_office_contact_number: ContactSettingsValue.IsBusinessOfficePhone,
          preference_bankxchange_chat: ContactSettingsValue.IsBankXchangeChatEnable,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${this.GetDynamicURL()}activateUser`,
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.GetDynamicURLAPIKey(),
          },
          data: data,
        };
        let result = await axios(config);
        return result;
      } catch (error) {
        console.log("Error", error);
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
    GetDynamicURL() {
      return window.location.hostname === "dev.bankxchange.com"
        ? "https://6cvpo8vzci.execute-api.us-east-1.amazonaws.com/dev/"
        : window.location.hostname === "test.bankxchange.com"
        ? "https://r8x3g0xmm8.execute-api.us-east-1.amazonaws.com/test/"
        : window.location.hostname === "uat.bankxchange.com"
        ? "https://t0x8z52egb.execute-api.us-east-1.amazonaws.com/uat/"
        : "https://1p1nnwv2k2.execute-api.us-east-1.amazonaws.com/live/";
    },
    GetDynamicURLAPIKey() {
      return window.location.hostname === "dev.bankxchange.com"
        ? "bMrmIdV3z3HVEaN6TycW5uWZ7uIMJ8laKzTtUepd"
        : window.location.hostname === "test.bankxchange.com"
        ? "RWeq9qTEI97Gh0JehvHu76Jqk9gX8MlY483LEmN9"
        : window.location.hostname === "uat.bankxchange.com"
        ? "CeAMkxHq6G1K9ytlnpWWX8So8mNPOw2XZjWzZqf4"
        : "YN1S5JEj17983TzmSPUqn2SK52ZtZmgXalrC0lzk";
    },
  },
};
