export const GetAPIKey = {
  data: () => ({}),
  methods: {
    GetAPIKeyMethod() {
      
      let token = jwt.sign({ foo: "bar" }, "shhhhh");
    //   const user = {
    //     id: "SZU",
    //     message: "GET_API_KEY",
    //     url: "https://www.bankxchange.com",
    //   };
    //   const secretKey = "SZU";
    //   const token = sign(user, secretKey, { expiresIn: "1m" });
      console.log("Check Token!!!", token);
    },
  },
};
