<template>
  <div>
    <v-dialog
      v-model="dialogForTermsAndConditions"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-toolbar dense class="elevation-0" color="">
          <div class="fontSize25px fontFamilyInter">Terms & Conditions</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogForTermsAndConditionsEmit(1)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <div class="fontStyleVariant2 pb-5">Text Coming Soon!!</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogForTermsAndConditions: Boolean,
  },
  methods: {
    dialogForTermsAndConditionsEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
